import apiBaseUrl from '../ApiBaseUrl.tsx';


const handleResponse = async (response: Response) => {
    if (!response.ok) {
        throw new Error(`Error fetching data: ${response.statusText}`);
    }
    const data = await response.json();
    return data;
};

export const fetchAllDepositMethods = async () => {
    var token = localStorage.getItem('authToken')?.toString();
    try {
        console.log('Fetching data from API...');
        const response = await fetch(`${apiBaseUrl.apiBaseUrl}/Deposit/GetAllDepositMethods`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        console.log('API response received:', response);
  
        if (!response.ok) {
            console.error('Response not OK:', response.statusText);
        } else {
            const data = await handleResponse(response);
            console.log('Data fetched successfully:', data);
            return data;
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const fetchAllDigitalCoinsById = async (depositMethodId: number) => {
    var token = localStorage.getItem('authToken')?.toString();
    console.log('DepositMethodId', depositMethodId);
    try {
        const response = await fetch(`${apiBaseUrl.apiBaseUrl}/Deposit/GetAllDigitalCoins?depositMethodId=${depositMethodId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        if (!response.ok) {
            console.error('Response not OK:', response.statusText);
        } else {
            const data = await handleResponse(response);
            console.log('Data fetched successfully:', data);
            return data;
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const DepositReceipt = async (formData: FormData) => {
    var token = localStorage.getItem('authToken')?.toString();
    console.log('Sending deposit data to API...');
    try {
        const response = await fetch(`${apiBaseUrl.apiBaseUrl}/Deposit/CreateDeposit`, {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': `Bearer ${token}`,
                // Uncomment the following line if your API requires specific headers
                // 'Content-Type': 'multipart/form-data',
            },
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Response not OK:', response.statusText, errorText);
            throw new Error(`Error fetching data: ${response.statusText}`);
        }
        else {
            const data = await handleResponse(response);
            console.log('Data fetched successfully:', data);
            return data;
        }

    } catch (error) {
        console.error('Error creating deposit:', error);
        throw error;
    }
};

// src/Common/SharedApis/DespositMethods.tsx
export const bindPocketAddress = async (data: { digitalCoinId: string, address: string, userId: string, lastModify: string }) => {
    var token = localStorage.getItem('authToken')?.toString();
    console.log('Sending deposit data to API...');
    try {
        const response = await fetch(`${apiBaseUrl.apiBaseUrl}/WalletAddress/AddOrUpdateWalletAddress`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });

        const responseText = await response.text(); // Read response as text

        if (!response.ok) {
            console.error('Response not OK:', response.statusText, responseText);
            throw new Error(`Error fetching data: ${response.statusText}`);
        } else {
            console.log('Response text:', responseText);
            // Process response as needed (e.g., display message or handle text response)
            return responseText;
        }
    } catch (error) {
        console.error('Error creating deposit:', error);
        throw error;
    }
};

export const currencyWithDraw = async (data: { requestUserId: string, withDrawAmount: number, withDrawToAddress: string, statusId: number, isActive: boolean }) => {
    var token = localStorage.getItem('authToken')?.toString();
    console.log('Sending withdrawal data to API...');
    try {
        const response = await fetch(`${apiBaseUrl.apiBaseUrl}/WithDraw/CreateWithdrawRequest`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });

        const responseText = await response.text(); // Read response as text

        if (!response.ok) {
            console.error('Response not OK:', response.statusText, responseText);
            throw new Error(`Error fetching data: ${response.statusText}`);
        } else {
            console.log('Response text:', responseText);
            return responseText;
        }
    } catch (error) {
        console.error('Error creating withdrawal:', error);
        throw error;
    }
};

export const walletadressbyuserid = async (userId: number) => {
    var token = localStorage.getItem('authToken')?.toString();
    const apiUrl = `${apiBaseUrl.apiBaseUrl}/WalletAddress/GetAllByUserId/${userId}`;
    console.log('Fetching from:', apiUrl);
    try {
        const response = await fetch(apiUrl, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        if (!response.ok) {
            const errorText = await response.text(); // Capture error details
            console.error('Response not OK:', response.statusText);
            console.error('Error details:', errorText);
            return null;
        }
        const data = await response.json();
        console.log('Data fetched successfully:', data);
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return null;
    }
};

export const getbalance = async (userId: string) => {
    var token = localStorage.getItem('authToken')?.toString();
    try {
        const response = await fetch(`${apiBaseUrl.apiBaseUrl}/Balance/GetBalanceByUserId?userId=${userId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        if (!response.ok) {
            console.error('Response not OK:', response.statusText);
            return null;
        }
        const data = await response.json();
        console.log('Data fetched successfully:', data);
        return data.avaliableBalance; // Return only the availableBalance
    } catch (error) {
        console.error('Error fetching data:', error);
        return null;
    }
};





