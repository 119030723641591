import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DepositReceipt as createDeposit } from '../../../Common/SharedApis/DespositMethods.tsx'; // Adjust the path as necessary
import { useLoading } from '../../../contexts/LoadingContext.tsx';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import toastify CSS
import './DepositReceipt.css';

const DepositReceipt: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { depositAmount,digitalCoinId,rechargeAddress } = location.state || {};
  const [remittanceCertificate, setRemittanceCertificate] = useState<File | null>(null);
  const { isLoading, setIsLoading } = useLoading();

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setRemittanceCertificate(event.target.files[0]);
    }
  };

  const handleSubmit = async () => {

    const userId=localStorage.getItem('userId')?.toString();

    if (!depositAmount || !remittanceCertificate) {
      toast.error('Please fill in all the required fields.');
      return;
    }

    setIsLoading(true); // Set loading state to true

    try {
      console.log('Submitting deposit...');

      const formData = new FormData();
      formData.append('Amount', depositAmount.toString());
      formData.append('RemittanceCertificate', remittanceCertificate);
      
      // Hardcoded values
      formData.append('UserId', userId || "");
      formData.append('StatusId', '1');
      formData.append('DigitalCoinId', digitalCoinId);
      formData.append('ExchangeRate', '1');
      formData.append('DepositFrom', '');
      formData.append('DepositTo', rechargeAddress);
    // Log FormData contents
      console.log('FormData contents:');
      for (const [key, value] of formData.entries()) {
        if (value instanceof File) {
          console.log(`${key}: ${value.name}`);
        } else {
          console.log(`${key}: ${value}`);
        }
      }

      const result = await createDeposit(formData);
      console.log('Deposit creation result:', result);

      toast.success('Deposit created successfully!');
      navigate('/DepositConfirmation', { state: { result } });
    } catch (error) {
      console.error('Error during deposit creation:', error);
      toast.error('There was an error processing your deposit. Please try again.');
    } finally {
      setIsLoading(false); // Set loading state to false after the operation
    }
  };

  return (
    <div className="receipt-container">
      <button className="back-button" onClick={() => navigate(-1)}>←</button>
      <h2>Fill in the deposit receipt</h2>
      <div className="instructions">
        After the transfer is completed, please be sure to fill in the deposit receipt information correctly so that the
        deposit can be processed without any issues.
      </div>
      <div className="deposit-amount">
        <label>Number of deposits (ETH)</label>
        <input
          type="number"
          value={depositAmount}
          placeholder="Enter amount"
          readOnly
        />
      </div>
      <div className="upload-certificate">
        <label htmlFor="upload-input" className="upload-label">
          {remittanceCertificate ? remittanceCertificate.name : 'Click to upload the remittance certificate'}
        </label>
        <input
          id="upload-input"
          type="file"
          onChange={handleFileUpload}
          className="upload-input"
        />
      </div>
      <button className="confirm-button" onClick={handleSubmit} disabled={isLoading}>
        {isLoading ? 'Processing...' : 'Confirm'}
      </button>
    </div>
  );
};

export default DepositReceipt;
