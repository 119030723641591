import React, { useEffect, useState } from 'react';
import { FaRegCircle, FaDollarSign, FaHeadphones, FaCoins, FaClock, FaFileContract, FaDownload, FaTh } from 'react-icons/fa';
import '../Options/DashboardOptions.css';
import StatusBar from '../../../contexts/StatusBar.tsx';

type DeferredPromptType = Event & {
  prompt: () => void;
  userChoice: Promise<{ outcome: string }>;
};

const DashboardIcons = () => {

  const [deferredPrompt, setDeferredPrompt] = useState<DeferredPromptType | null>(null);
  const [statusMessage, setStatusMessage] = useState('');
  const [showStatusBar, setShowStatusBar] = useState(false); // Initially false

  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);
  
  const isAppInstalled = () => {
    return window.matchMedia('(display-mode: standalone)').matches;
  };

  const handleDownloadClick = () => {
    if (deferredPrompt && !isAppInstalled()) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        setDeferredPrompt(null);
      });
    } else {
      setStatusMessage('App installation is not supported or already installed. Please install the app from the browser menu.');
      setShowStatusBar(true);  // Show the status bar with the message
    }
  };


  const handleCloseStatusBar = () => {
    setShowStatusBar(false);  // Close the status bar
    setStatusMessage(''); // Reset the message to prevent it from showing again
  };


  return (
    <>
    <StatusBar message={statusMessage} show={!!statusMessage}   onClose={handleCloseStatusBar}/>

    <div className="dashboard-icons">
      <a href="DepositMethods" rel="noopener noreferrer" className="icon">
      <FaRegCircle />
        <span>Deposit</span>
      </a>

      <a href="WithdrawMethods" rel="noopener noreferrer" className="icon">
        <FaDollarSign />
        <span>Withdraw</span>
      </a>

      <a href="serviceCenter" rel="noopener noreferrer" className="icon">
        <FaHeadphones />
        <span>Online service</span>
      </a>

      <div className="icon">
        <FaCoins />
        <span>New Coins</span>
      </div>
      <div className="icon">
        <FaClock />
        <span>Time Limit</span>
      </div>
      <div className="icon">
        <FaFileContract />
        <span>Contract</span>
      </div>
      <div className="icon" onClick={handleDownloadClick}>
        <FaDownload />
        <span>Download APP</span>
      </div>
      <div className="icon">
        <FaTh />
        <span>More</span>
      </div>
    </div>
    </>
  );
}

export default DashboardIcons;
