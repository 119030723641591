import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './TimerPage.css'; // Import the CSS file

const TimerPage: React.FC = () => {
    const location = useLocation();
    const { state } = location;
    const [timeLeft, setTimeLeft] = useState<number | null>(state?.duration || null);
    const navigate = useNavigate();

    useEffect(() => {
        if (timeLeft !== null && timeLeft > 0) {
            const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
            return () => clearTimeout(timer);
        } else if (timeLeft === 0) {
            // Handle trade result notification or any other logic when the timer ends
            console.log('Timer ended. Fetch result or notify user.');
            navigate('/result-page'); // Navigate to the result page
        }
    }, [timeLeft, navigate]);

    return (
        <div className="timer-page">
            <h1>Time Left: {timeLeft} seconds</h1>
        </div>
    );
};

export default TimerPage;
