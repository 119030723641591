import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from '../App/Dashboard/Home.tsx';  // Import your HomePage component
import MarketPage from '../App/Dashboard/Market.tsx'; 
import TradePage from '../App/Dashboard/Trade.tsx'; 
import OrderPage from '../App/Dashboard/Order.tsx'; 
import BalancesPage from '../App/Dashboard/Balance/BalancesPage.tsx'; 
import SearchBar from '../App/Dashboard/SearchBar.tsx'; 
import ServiceCenter from '../App/Components/ServiceCenter/ServiceCenter.tsx'; 
import Chat from '../App/Dashboard/Chat.tsx'; 

import Settings from '../App/DrawerPages/Settings.tsx'; 
import BottomNav from './BottomNav.tsx';  
import Header from './Header.tsx'; 
import Footer from './Footer.tsx'; 
import OrderConfirmation from '../App/Dashboard/OrderConfirmation.tsx';
import CryptoDashboard from '../App/Components/CryptoDashboard.tsx';
import DepositMethod from '../App/Dashboard/Deposite/DepositMethod.tsx';

import DigitalCoins from '../App/Dashboard/Deposite/DigitalCoins.tsx';
import DigitalCurrencyDeposit from '../App/Dashboard/Deposite/DigitalCurrencyDeposit.tsx';
import DepositReceipt from '../App/Dashboard/Deposite/DepositReceipt.tsx';
import WithDrawMethods from '../App/Dashboard/WithDraw/WithDrawMethods.tsx';
import CurrencyWithDraw from '../App/Dashboard/WithDraw/CurrencyWithDraw.tsx';
import PocketAddr from '../App/Dashboard/PocketAddress/PocketAddr.tsx';
import BindPocketAddress from '../App/Dashboard/PocketAddress/BindPocketAddress.tsx';
import CheckLoader from '../App/Test/checkLoader.tsx';
import TimerPage from '../App/Dashboard/TimerPage.tsx';
import NotFoundPage from '../App/Dashboard/NotFoundPage.tsx';
import ResultPage from '../App/Dashboard/result-page.tsx';

const DashboardLayout = () => {
  return (
    <>
      <Header />
      <main>
        <Routes>
            <Route path="home" element={<HomePage />} />  
            <Route path="market" element={<MarketPage />} />
            <Route path="trade" element={<TradePage/>} />
            <Route path="order" element={<OrderPage/>} />
            <Route path="balances" element={<BalancesPage/>} />
            <Route path="search" element={<SearchBar/>} />
            <Route path="serviceCenter" element={<ServiceCenter/>} />
            //Drawer Layout Menus
            <Route path="settings" element={<Settings/>} />
            <Route path="chat" element={<Chat/>} />
            <Route path="OrderConfirmation" element={<OrderConfirmation/>} />
            <Route path="crypto" element={<CryptoDashboard/>} />
            <Route path="DepositMethods" element={<DepositMethod/>} />
            <Route path="digitalCoins/:depositMethodId" element={<DigitalCoins />} />
            <Route path="DigitalCurrencyDeposit/:digitalCoinId" element={<DigitalCurrencyDeposit />} />
            <Route path="DepositReceipt" element={<DepositReceipt />} />
            <Route path="WithdrawMethods" element={<WithDrawMethods />} />
            <Route path="CurrencyWithDraw/:depositMethodId" element={<CurrencyWithDraw />} />
            <Route path="pocketAddr" element={<PocketAddr />} />
            <Route path="BindPocketAddress/:digitalCoinId" element={<BindPocketAddress />} />
            <Route path="timer-page" element={<TimerPage />} />
            <Route path="timer-page" element={<TimerPage />} />
            <Route path="result-page" element={<ResultPage />} />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
        </main>
      <BottomNav />
      <Footer />
    
    </>
  );
};

export default DashboardLayout;
