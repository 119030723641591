import React, { useEffect, useState, useCallback } from 'react';
import TradingViewChart from '../Components/Charts/TradingViewChart.tsx';
import './Market.css';
import { useNavigate } from 'react-router-dom';
import { useLoading } from '../../contexts/LoadingContext.tsx';

interface MarketOrderItem {
  icon: string;
  name: string;
  lastPrice: number;
  change: number;
  symbol: string;
}

const Market = () => {
  const [data, setData] = useState<MarketOrderItem[]>([]);
  const [selectedSymbol, setSelectedSymbol] = useState<string | null>(null);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [latestValue, setLatestValue] = useState<number | null>(null);
  const [webSockets, setWebSockets] = useState<{ [key: string]: WebSocket }>({});
  const navigate = useNavigate();
  const { isLoading, setIsLoading } = useLoading();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Set loading to true before fetching data
      try {
        const response = await fetch(
          'https://api.allorigins.win/get?url=' + encodeURIComponent('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd')
        );
        const result = await response.json();
        const marketData = JSON.parse(result.contents).map((item: any) => ({
          icon: item.image,
          name: item.name,
          lastPrice: item.current_price,
          change: item.price_change_percentage_24h,
          symbol: item.symbol,
        }));
        setData(marketData);

        marketData.forEach((item) => {
          if (!webSockets[item.symbol]) {
            const ws = new WebSocket(`wss://stream.binance.com:9443/ws/${item.symbol.toLowerCase()}@trade`);

            ws.onopen = () => {
              console.log(`WebSocket connection established for ${item.symbol}.`);
            };

            ws.onmessage = (event) => {
              try {
                const data = JSON.parse(event.data);
                const latestPrice = parseFloat(data.p);
                setData((prevData) =>
                  prevData.map((prevItem) =>
                    prevItem.symbol === item.symbol
                      ? { ...prevItem, lastPrice: latestPrice }
                      : prevItem
                  )
                );
              } catch (error) {
                console.error('Error processing WebSocket message:', error);
              }
            };

            ws.onerror = (error) => {
              console.error('WebSocket error:', error);
            };

            ws.onclose = () => {
              console.log(`WebSocket connection closed for ${item.symbol}.`);
            };

            setWebSockets((prevWs) => ({ ...prevWs, [item.symbol]: ws }));
          }
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false); // Set loading to false after fetching data
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 10000);

    return () => {
      clearInterval(intervalId);
      Object.values(webSockets).forEach((ws) => ws.close());
    };
  }, [webSockets, setIsLoading]);

  const handleCurrencyClick = useCallback((item: MarketOrderItem) => {
    setSelectedSymbol(item.symbol);
    setLatestValue(item.lastPrice);
    setIsFullscreen(true);
  }, []);

  const handleBuyUpClick = useCallback(() => {
    navigate('/OrderConfirmation', { state: { symbol: selectedSymbol, price: latestValue,Type:'BuyUp' } });
  }, [navigate, selectedSymbol, latestValue]);

  const handleBuyDownClick = useCallback(() => {
    navigate('/OrderConfirmation', { state: { symbol: selectedSymbol, price: latestValue ,Type:'BuyDown'} });
  }, [navigate, selectedSymbol, latestValue]);

  return (
    <div className={`market-order ${isFullscreen ? 'fullscreen' : ''}`}>
      {isFullscreen ? (
        <div className="fullscreen-overlay">
          <button className="back-button" onClick={() => setIsFullscreen(false)}>
            &larr; back
          </button>
          <div className="chart-container">
            <h3 className="chart-title">Currency Symbol: {selectedSymbol?.toUpperCase()}</h3>
            <TradingViewChart 
              selectedSymbol={selectedSymbol} 
              onValueUpdate={setLatestValue} 
            />
          </div>
          <div className="trading-buttons">
            <button className="buy-up-button" onClick={handleBuyUpClick}>
              Buy Up
            </button>
            <button className="buy-down-button" onClick={handleBuyDownClick}>
              Buy Down
            </button>
          </div>
          {latestValue !== null && (
            <div className="latest-price">
              <h3>Latest Price:</h3>
              <span>${latestValue.toFixed(2)}</span>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="header">
            <h2>Market Order</h2>
            <a href="#">More</a>
          </div>
          <div className="tabs">
            <div className="tab active">Time Limit</div>
            <div className="tab">Derivatives</div>
          </div>
          <div className="table">
            <div className="table-header">
              <span>Products</span>
              <span>Last Price</span>
              <span>Today Change</span>
            </div>
            {data.map((item, index) => (
              <div key={index} className="table-row" onClick={() => handleCurrencyClick(item)}>
                <span className="product">
                  <img src={item.icon} alt={item.name} /> {item.name}
                </span>
                <span className="last-price">${item.lastPrice.toFixed(2)}</span>
                <span className={`today-change ${item.change > 0 ? 'positive' : 'negative'}`}>
                  {item.change.toFixed(2)}%
                </span>
              </div>
            ))}
          </div>
          {selectedSymbol && (
            <div className="selected-symbol">
              <h3>Selected Currency Symbol:</h3>
              <p>{selectedSymbol.toUpperCase()}</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Market;
