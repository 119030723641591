import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TradingViewData from './TradingViewData.tsx';
import { createTrade } from '../../Common/SharedApis/Trade.tsx'; // Import the createTrade function

import './OrderConfirmation.css';

const TimeLimit: React.FC = () => {
    const location = useLocation();
    const { state } = location;
    const [selectedDuration, setSelectedDuration] = useState<number>(30);
    const [latestPrice, setLatestPrice] = useState<number>(state?.price || 0);
    const [purchaseAmount, setPurchaseAmount] = useState<number>(100); // Default value
    const [fixedPrice, setFixedPrice] = useState<number>(0);
    const [available, setAvailable] = useState<number>(0);
    const [charges, setCharges] = useState<number>(0.2); // Example charge
    var [productName, setProductName] = useState<string>(state?.symbol);
    const [type, setType] = useState<string>(state?.Type || "");
    const [isConfirming, setIsConfirming] = useState<boolean>(false);
    const [expectedProfit, setExpectedProfit] = useState<number>(0); // Add state for expected profit
    const navigate = useNavigate();

    useEffect(() => {
     

        if (state?.symbol) {
            const ws = new WebSocket(`wss://stream.binance.com:9443/ws/${state.symbol.toLowerCase()}@trade`);

            ws.onopen = () => {
                console.log('WebSocket connection established.');
            };

            ws.onmessage = (event) => {
                try {
                    const data = JSON.parse(event.data);
                    const latestPrice = parseFloat(data.p);
                    console.log('Received data from WebSocket:', data);
                    console.log('Latest Price:', latestPrice);
                    setLatestPrice(latestPrice);
                } catch (error) {
                    console.error('Error processing WebSocket message:', error);
                }
            };

            ws.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            ws.onclose = () => {
                console.log('WebSocket connection closed.');
            };

            return () => {
                ws.close();
            };
        }
    }, [state?.symbol]);

      // Calculate profit based on selected duration and purchase amount
      useEffect(() => {
        let profitPercentage = 0;
        switch (selectedDuration) {
            case 30:
                profitPercentage = 40;
                break;
            case 60:
                profitPercentage = 50;
                break;
            case 90:
                profitPercentage = 60;
                break;
            default:
                profitPercentage = 0;
        }
        const calculatedProfit = (purchaseAmount * profitPercentage) / 100;
        setExpectedProfit(calculatedProfit);
    }, [selectedDuration, purchaseAmount]);

    const handleDurationChange = (duration: number) => {
        setSelectedDuration(duration);
    };

    const handleAmountClick = (amount: number) => {
        setPurchaseAmount(amount);
    };

    const handleConfirmOrder = async () => {
        try {
            setIsConfirming(true);
          console.log('click id',localStorage.getItem('userId')?.toString());
        // Construct the trade object
            const trade = {
                userId: localStorage.getItem('userId')?.toString(), // Replace with a valid user ID
                digitalCoinName: state?.symbol,
                tradingAmount: purchaseAmount,
                status: 1,
                tradeDuration: selectedDuration,
                expectedProfit: expectedProfit,
            };

            // Call the createTrade API
            // const result = await testCreateTrade();
            const result=await createTrade(trade);
            if (result) {
                console.log('Trade created successfully:', result);
                navigate('/timer-page', { state: { duration: selectedDuration } });
            }
        } catch (error) {
            console.error('Error confirming order:', error);
        } finally {
            setIsConfirming(false);
        }
    };


    return (
        <div className="order-container">
           
            {/* <TradingViewData
                setFixedPrice={setFixedPrice}
                setAvailable={setAvailable}
                setProductName={setProductName}
            /> */}

            <div className="order-header">
                <span className="back-icon" onClick={() => navigate('/')}>←</span>
                <h2 className="title">Time Limit</h2>
            </div>
            <div className="order-details">
                <div className="order-row">
                    <span>Product name</span>
                    <span>{productName}</span>
                    <span>Type</span>
                    <span className="buy-up">{type}</span>
                </div>
                <div className="order-row">
                    <span>Purchase amount</span>
                    <span>${purchaseAmount}</span>
                    <span>Fixed price</span>
                    <span>{fixedPrice.toFixed(2)}</span>
                </div>
                <div className="order-row">
                    <span>Available</span>
                    <span>{available.toFixed(2)}</span>
                    <span>Charges</span>
                    <span>{charges}%</span>
                </div>
            </div>
            <div className="order-duration">
                <h3>Deliver time</h3>
                <div className="duration-options">
                    <div
                        className={`duration-option ${selectedDuration === 30 ? 'selected' : ''}`}
                        onClick={() => handleDurationChange(30)}
                    >
                        <span>Duration</span>
                        <span className="duration-time">30S</span>
                        <span className="duration-profit">Profit 40%</span>
                    </div>
                    <div
                        className={`duration-option ${selectedDuration === 60 ? 'selected' : ''}`}
                        onClick={() => handleDurationChange(60)}
                    >
                        <span>Duration</span>
                        <span className="duration-time">60S</span>
                        <span className="duration-profit">Profit 50%</span>
                    </div>
                    <div
                        className={`duration-option ${selectedDuration === 90 ? 'selected' : ''}`}
                        onClick={() => handleDurationChange(90)}
                    >
                        <span>Duration</span>
                        <span className="duration-time">90S</span>
                        <span className="duration-profit">Profit 60%</span>
                    </div>
                </div>
            </div>
            <div className="purchase-amount">
                <h3>Purchase amount</h3>
                <input
                    type="number"
                    min="10"
                    value={purchaseAmount}
                    readOnly
                />
                <div className="amount-buttons">
                    {[100, 200, 300, 400, 1000, 10000].map(amount => (
                        <button key={amount} onClick={() => handleAmountClick(amount)}>
                            ${amount}
                        </button>
                    ))}
                </div>
                <div className="amount-info">
                    <span>Expected profit: ${expectedProfit.toFixed(2)}</span>
                    <span>Occupation deposit: $0</span>
                </div>
            </div>
            <button
                className="confirm-button"
                onClick={handleConfirmOrder}
                disabled={isConfirming}
            >
                {isConfirming ? 'Confirming...' : 'Confirm order'}
            </button>          
        </div>
    );
};

export default TimeLimit;
