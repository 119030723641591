import React from 'react';
import SearchBar from './SearchBar.tsx';
import CustomSlider from '../Components/Slider/CustomSlider.tsx';
import DashboardIcons from '../Components/Options/DashboardOptions.tsx';
import HomeContainer from './HomeContainer.tsx';
import './Home.css'; // Custom styles


function Home() {
    return (
        <HomeContainer>
            {/* <h1>Home</h1> */}
            {/* Add your home page content here */}
          <SearchBar/>
            <CustomSlider />

            <DashboardIcons />
        </HomeContainer>
    );
}

export default Home;
