import React from 'react';

function Order() {
    return (
        <div>
            <h1>Order</h1>
            {/* Add your order page content here */}
        </div>
    );
}

export default Order;
